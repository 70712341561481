import React, { useState, useMemo, useEffect } from "react";
import { Star, Eye } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { SadEmoji } from "./icons";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import {
  handleDateChange,
  setSelectedLocation,
  setweeklyOperationsData,
} from "../redux/slices/filter-slice";

const globalStatus = {
  1: "Active",
  2: "Trial",
  3: "Churned",
};

export const Table = ({ tableData, loading }) => {
  
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  function getColorCode(op_score) {
    if (op_score >= 85) return "Dark Green";
    else if (op_score >= 75 && op_score <= 84) return "Light Green";
    else if (op_score >= 50 && op_score < 75) return "Amber";
    else return "Red";
  }

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const primary6 = "#515F6F";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationDate = useSelector((state) => state?.filter?.locationDates);

  const formatCurrency = (amount, isSmallScreen) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      maximumFractionDigits: isSmallScreen ? 0 : 2, // No decimals if small screen
    });
  
    let formattedAmount = formatter.format(amount);
    formattedAmount = formattedAmount.replace(/^£/, "£ ");
    return formattedAmount;
  };

  const handleClickNavigate = (location, SelectedData) => {
    dispatch(
      handleDateChange({
        id: "weeklyDates",
        startDate: SelectedData.start,
        endDate: SelectedData.end,
      })
    );
    dispatch(setweeklyOperationsData([location.location]));
    dispatch(setSelectedLocation([location.location]));

    // navigate(`/weekly-operations?redirect=true&rating=${location?.rating}`);
    navigate(`/weekly-operations?redirect=true`);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedTableData = useMemo(() => {
    const sortableData = [...tableData];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        // Check if the sorting key is "weekly_sales" or "rating" to handle numeric string values
        if (
          sortConfig.key === "weekly_sales" ||
          sortConfig.key === "rating" ||
          sortConfig.key === "op_score"
        ) {
          // Convert rating or weekly_sales to numbers for proper numeric sorting
          const aValue = parseFloat(a[sortConfig.key]);
          const bValue = parseFloat(b[sortConfig.key]);
          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        } else {
          // Default string comparison for other fields
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
    }
    return sortableData;
  }, [tableData, sortConfig]);

  const renderTableContent = () => {
    if (loading) {
      return Array.from({ length: 10 }).map((_, index) => (
        <tr key={index} className="bg-white border-b hover:bg-gray-50">
          <td className="px-2 sm:px-4 py-2 sm:py-3 w-10">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden sm:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden lg:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 hidden xl:table-cell">
            <Skeleton />
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3">
            <Skeleton circle width={20} height={20} />
          </td>
        </tr>
      ));
    }

    if (!loading && (sortedTableData.length === 0 || tableData.length === 0)) {
      return (
        <tr className="bg-gray-50 roboto-font-table">
          <td
            colSpan="6"
            className="px-2 sm:px-4 py-12 sm:py-24 text-center roboto-font-table "
          >
            <div className="flex flex-col items-center justify-center">
              <SadEmoji />
              <p className="text-gray-900 font-semibold text-lg sm:text-xl">
                No records found
              </p>
              <p className="text-gray-500 mt-2 text-sm sm:text-base roboto-font-table ">
                Try adjusting your search or filter to find what you're looking
                for.
              </p>
            </div>
          </td>
        </tr>
      );
    }

    return sortedTableData.map((row, index) => (
      <>
        <tr
          key={index}
          className="bg-white border-t border-b hover:bg-gray-50 roboto-font-table "
        >
          <td
            className={`truncate-text px-2 sm:px-4 py-2 sm:py-3 text-primary-7 text-base font-normal roboto-font-table ${
              parseInt(row?.status) === 2
                ? "text-orange-600"
                : parseInt(row?.status) === 1
                ? "text-primary-7"
                : "text-primary-6"
            }`}
            data-tooltip-id="location-tooltip" // Changed attribute
            data-tooltip-content={row?.location} // Changed attribute
          >
            <div
              className=" justify-center gap-2 cursor-pointer roboto-font-table "
              onClick={() => {
                handleClickNavigate(row, {
                  start: locationDate?.start,
                  end: locationDate?.end,
                });
              }}
            >
              {row?.location.split("-")[0]}
            </div>
          </td>
          <td
            className={`truncate-text px-2 sm:px-4 py-2 sm:py-3 text-primary-7 text-base font-normal roboto-font-table ${
              parseInt(row?.status) === 2
                ? "text-orange-600"
                : parseInt(row?.status) === 1
                ? "text-primary-7"
                : "text-primary-6"
            }`}
            data-tooltip-id="location-tooltip" // Changed attribute
            data-tooltip-content={row?.location} // Changed attribute
          >
            <div
              className=" justify-center gap-2 cursor-pointer roboto-font-table "
              onClick={() => {
                handleClickNavigate(row, {
                  start: locationDate?.start,
                  end: locationDate?.end,
                });
              }}
            >
              {row?.location}
            </div>
          </td>

          <td
            className="px-2 sm:px-4 py-2 sm:py-3 sm:table-cell text-primary-7 text-base font-normal roboto-font-table"
            data-tooltip-id="Weekly-tooltip"
            data-tooltip-content={`Overall Sales: ${row?.weekly_sales}`}
          >
            {formatCurrency(row?.weekly_sales, isSmallScreen)}
          </td>
          {/* <td
            className={`px-2 sm:px-4 py-2 sm:py-3 hidden md:table-cell roboto-font-table ${
              parseInt(row?.status) === 2
                ? "text-orange-600 text-base font-medium"
                : parseInt(row?.status) === 1
                ? `text-[#12B366] text-base font-medium`
                : `text-${primary6} text-base font-medium`
            }`}
          >
            {globalStatus[row?.status]}
          </td> */}
          <td className="px-2 sm:px-4 py-2 sm:py-3 md:table-cell text-base font-medium roboto-font-table">
            <div
              className={`${"rounded-oval-red"}`}
            >
                0.0
                <Star
                  size={18}
                  className="font-semibold bold-star-icon"
                  color={"#B30625"}
                />
            </div>
          </td>
          <td className="px-2 sm:px-4 py-2 sm:py-3 md:table-cell text-base font-medium roboto-font-table">
            <div
              className={`${
                row?.rating >= 4.7
                  ? "rounded-oval-dark-green"
                  : row?.rating >= 4.4 && row?.rating < 4.7
                  ? "rounded-oval-light-green"
                  : row?.rating >= 4.0 && row?.rating < 4.4
                  ? "rounded-oval-amber"
                  : "rounded-oval-red"
              }`}
            >
              {row?.rating !== undefined &&
              row?.rating !== "" &&
              !isNaN(row?.rating)
                ? parseFloat(row?.rating).toFixed(1)
                : "0.0"}
              {row?.rating !== "-" && (
                <Star
                  size={18}
                  className="font-semibold bold-star-icon"
                  color={
                    row?.rating >= 4.7
                      ? "#0C4E28" // Dark green
                      : row?.rating >= 4.4 && row?.rating < 4.7
                      ? "#12B366" // Light green
                      : row?.rating >= 4.0 && row?.rating < 4.4
                      ? "#FFC107" // Amber
                      : "#B30625" // Red
                  }
                />
              )}
            </div>
          </td>
          <td
            className="px-2 sm:px-4 py-2 sm:py-3 xl:table-cell text-base font-medium roboto-font-table "
            data-tooltip-id={`op-score-${row.location}`} // Make the tooltip ID unique
          >
            <div
              className={`text-base font-medium ${
                getColorCode(parseInt(row?.op_score)) === "Dark Green"
                  ? "rounded-oval-dark-green" // Dark Green
                  : getColorCode(parseInt(row?.op_score)) === "Light Green"
                  ? "rounded-oval-light-green" // Light Green
                  : getColorCode(parseInt(row?.op_score)) === "Amber"
                  ? "rounded-oval-amber" // Amber
                  : "rounded-oval-red" // Red
              }`}
            >
              {parseInt(row?.op_score) + "%"}
            </div>

            {/* Tooltip component for rendering tooltip */}
            <Tooltip id={`op-score-${row.location}`} place="bottom">
              <div>
                Overall Rider Waiting Time: {row?.overall_rider_Waiting_time}{" "}
                mins
              </div>
              <div>Overall Open Rate: {row?.overall_openrate}%</div>
              <div>Inaccurate Orders: {row?.inaccurate_order}%</div>
            </Tooltip>
          </td>

          <td className="px-2 sm:px-4 py-2 sm:py-3 roboto-font-table ">
            <button
              className=" justify-center gap-2"
              onClick={() => {
                handleClickNavigate(row, {
                  start: locationDate?.start,
                  end: locationDate?.end,
                });
              }}
            >
              <span className="text-primary-7 text-sm font-semibold sm:inline hover:underline">
                View
              </span>
            </button>
          </td>
        </tr>
      </>
    ));
  };

  return (
    <div className="w-full overflow-x-auto max-h-auto custom-scrollbar roboto-font-table">
      <table className="table-fixed min-w-full divide-y divide-gray-200 sticky top-0 roboto-font-table">
        <thead className="bg-primary-2 sticky top-0 ">
          <tr className="roboto-font-table">
            <th
              onClick={() => handleSort("location")}
              className="roboto-font-table cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md  relative  text-primary-6  text-base font-medium "
            >
              Brand
              {sortConfig.key === "location" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ))}
            </th>
            <th
              onClick={() => handleSort("location")}
              className="roboto-font-table cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md  relative  text-primary-6  text-base font-medium "
            >
              Location
              {sortConfig.key === "location" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-12"
                    size={15}
                  />
                ))}
            </th>

            <th
              onClick={() => handleSort("weekly_sales")}
              className="roboto-font-table cursor-pointer px-5 py-3  bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              Weekly Sales
              {sortConfig.key === "weekly_sales" &&
                (sortConfig.direction === "asc" ? (
                  <IoIosArrowUp
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ) : (
                  <IoIosArrowDown
                    className="absolute top-[17px] right-6"
                    size={15}
                  />
                ))}
            </th>

            <th
              onClick={() => handleSort("status")}
              className="roboto-font-table cursor-pointer px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              App Rating
              {sortConfig.key === "status" && (
                <>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </>
              )}
            </th>
            <th
              onClick={() => handleSort("rating")}
              className="cursor-pointer roboto-font-table px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
              style={{maxWidth: "115px"}}
            >
              Weekly Ratings
              {sortConfig.key === "rating" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-2"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th
              onClick={() => handleSort("op_score")}
              className=" cursor-pointer roboto-font-table  px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium relative"
            >
              OP Score
              {sortConfig.key === "op_score" && (
                <span>
                  {sortConfig.direction === "asc" ? (
                    <IoIosArrowUp
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  ) : (
                    <IoIosArrowDown
                      className="absolute top-[17px] right-6"
                      size={15}
                    />
                  )}
                </span>
              )}
            </th>
            <th className="cursor-pointer roboto-font-table px-5 py-3 bg-primary-2 text-start tracking-wider rounded-tl-md text-primary-6  text-base font-medium">
              <Eye size={16} />
            </th>
          </tr>
        </thead>
        <tbody>
          {renderTableContent()}
          <Tooltip id="location-tooltip" />
          <Tooltip id="Weekly-tooltip" />
          <Tooltip id="op-score" />
        </tbody>
      </table>
    </div>
  );
};
